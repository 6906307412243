.file_loading {
  color: transparent;
  outline: none;
  min-width: 150px !important;
}
.file_loading {
  // min-height: 56px;
}
.container {
  input {
    width: 100px;
  }
}
.file_input::-webkit-file-upload-button {
  visibility: hidden;
}
.file_input::before {
  content: "Chọn tệp";
  color: #01a350;
  display: inline-block;
  border: 1px solid #01a350;
  border-radius: 999px;
  padding: 10px 16px;
  width: 100px;
  text-align: center;
  outline: none;
  white-space: nowrap;
  user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 500;
  font-size: 10pt;
  &:hover {
    border-color: #618833;
    border-style: dotted !important;
  }
}
.file_input:active {
  outline: 0;
}
.file_input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

.file_input:disabled {
  cursor: not-allowed !important;
  &::before {
    border-color: rgba(0, 0, 0, 0.38);
    color: rgba(0, 0, 0, 0.38);
  }
}

.file__name {
  margin-left: 4px;
  max-width: 300px;
}
