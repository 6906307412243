.info__table {
  th,
  td {
    padding: 3px 4px;
    font-weight: normal;
    text-align: left;
  }
}

.account__info {
  width: full;
  display: flex;
  flex: 0 0 auto;
}

.chart_container {
  flex: 1 1 auto;
}
.box {
  display: flex;
  flex-direction: column; 
  width: 100%;
  & h3 {
    text-align: center;
    color: var(--primary-color);
    margin: 0;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 16px;
    margin-bottom: 16px;
  }
  &__action {
    margin-top: 24px;
    border: solid 1px var(--primary-color);
    padding: 6px 24px;
    border-radius: 999px;
    color: var(--primary-color);
    margin-bottom: 0 !important;
    text-transform: uppercase;
  }
}

.chart_container {
  padding: 0;
  width: 100%;
  .chart {
    height: 163px;
    position: relative;
    .caption {
      text-align: center;
      position: absolute;
      top: 35%;
      right: 51px;
      font-weight: 500;
    }
  }
  .chart_result {
    margin-left: 0px;
    .caption {
      left: -20%;
    }
  }
}

.not_data {
  height: 188px;
  width: 100%;
  min-width: 295px;
  font-size: 16px;
  display: flex; 
  align-items: center; 
  justify-content: center; 
  color: rgba($color: #000000, $alpha: 0.54);
}

.resend {
  color: var(--primary-color);
  font-weight: 500;
  cursor: pointer;
}
