@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background: #f6f9fc !important;
  overflow: hidden;
}
/* .MuiOutlinedInput-input {
  padding: 6px 16px !important;
} */
#modal-window {
  position: absolute;
  width: 99%;
  top: 0;
  z-index: 99999;
  font-family: sans-serif;
}

.modal-window {
  top: 0;
  left: 0;
  will-change: transform;
  margin-top: 2em;
  position: absolute;
  background: rgba(30, 32, 44, 0.99);
  min-width: 150px;
  height: initial;
  border-radius: 3px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  z-index: 9999;
  border: 1px solid #333748;
}

.modal-window-inner-border {
  min-height: 200px;
  height: initial;
  display: flex;
  border-radius: 3px;
  box-shadow: 0 0 0 0.4px #222132;
}

.modal-window-inner-border:before {
  position: absolute;
  top: 0.4px;
  left: 0.4px;
  right: 0.4px;
  bottom: 0.4px;
  border: 0.5px solid #57566d;
  border-radius: 3px;
}

.modal-handle {
  cursor: move;
  height: 15px;
  border-radius: 3px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.modal-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: white;
  padding: 0.5rem 0.5rem 2.5rem 0.5rem;
  font-size: 14px;
  line-height: 24px;
  height: 275px;
  min-width: 400px;
  overflow: auto;
}

.modal-content-drawer-handle {
  height: 100%;
  width: 20px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-close-button {
  cursor: pointer;
}

.modal-body {
  display: flex;
  flex-direction: column;
}

.modal-button {
  background-image: linear-gradient(25deg, #21d4fd, #b721ff 50%, #017eff);
  background-size: 500%;
  border: none;
  border-radius: 3px;
  box-shadow: 0 3px 0 0 #5664a7;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  height: 40px;
  width: 150px;
}

/* Update */

#fb-root {
  display: none !important;
}

.MuiPaper-root.MuiPaper-elevation0 {
  box-shadow: none !important;
  /* background: red; */
  border: 1px solid #efefef;
}

.MuiPaper-root.MuiPaper-rounded {
  box-shadow: none !important;
  /* background: red; */
  border: 1px solid #efefef;
}