.wrapper {
  // border: 1px solid rgb(222, 225, 230);
  border-radius: 8px;
  position: relative;
  padding: 30px 20px 16px;
  width: 100%;
}

.header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 16px;
  position: absolute;
  background-color: #fff;

  position: absolute;
  z-index: 9999;
  top: -20px;
  left: 20px;
  padding: 0 8px;

  h2 {
    font-size: 20px !important;
    margin-right: 12px !important;
  }
}

.openListBtn {
  background: none;
  padding: 0;
}

.openListBtnWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #636ae8; // Màu xanh lá cho nút gửi tin nhắn
  font-weight: 600;
  padding: 8px;
  border-radius: 4px;
  transition: all 0.3s ease; // Hiệu ứng chuyển mượt

  &:hover {
    background-color: rgba(99, 106, 232, 0.1); // Nền xanh lá nhạt khi hover
  }
}

.openListIcon {
  font-size: 16px;
  margin-right: 6px;
}

.openListTitle {
  margin: 0;
  font-size: 12px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.statsChartWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.overallTitle {
  color: #9095a0;
  font-size: 14px !important;
  font-weight: 600;
  white-space: nowrap;
}

.overallNumber {
  color: #171a1f;
  font-size: 34px !important;
  font-weight: 600;
}

.chartItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chartItemContent {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 8px 0;
}

.statsTitle {
  color: #9095a0;
  font-size: 13px !important;
  // font-weight: 600;
  white-space: nowrap;
}

.statsNumber {
  color: #171a1f;
  font-size: 20px !important;
  font-weight: 500;
}

.statsPercent {
  font-size: 14px !important;
  font-weight: 400;
  color: #636ae8;
  margin-top: 4px !important;
}

.chart {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 340px;
  padding: 0 16px;
}

.phoneNumChart {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
