@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500&display=swap');
@import '~bootstrap/scss/bootstrap';

:root {
  --primary-color: #05ae34;
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-main: #C1C1C1FF;
  --bs-gray-dark: #343a40;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
}

html,
body {
  height: 100%;
  margin: 0;
  font-family: 'Roboto', sans-serif !important;
  font-size: 14px;
}
p {
  margin: 0;
}
h4 {
  color: #05ae34;
  font-size: 16px;
}
#root {
  height: 100%;
}
$fws: 300, 400, 500, 600, 700;
@each $fw in $fws {
  .fw-#{$fw} {
    font-weight: $fw !important;
  }
}

@for $i from 5 to 63 {
  .fz-#{$i} {
    font-size: $i * 1px !important;
  }
}

@for $i from 0 to 500 {
  @if $i % 5 == 0 {
    .minw_#{$i} {
      min-width: $i * 1px !important;
    }
  }
}

@for $i from 0 to 500 {
  @if $i % 5 == 0 {
    .maxw_#{$i} {
      max-width: $i * 1px !important;
    }
  }
}
.text-primary {
  color: var(--primary-color) !important;
}
.text-danger {
  color: var(--bs-danger) !important;
}
h3 {
  font-size: 20px;
  font-weight: 700;
}

.row__custom {
  &:hover {
    td {
      transition: ease-in-out 0.3s all;
      cursor: pointer;
      color: white;
      background-color: var(--bs-gray);
    }
  }
}

// .btn--rounder {
//   border-radius: 999px !important;
//   padding: 6px 22px !important;
//   text-transform: none !important;
//   box-shadow: none !important;
//   transition: ease-in all 0.3s;
//   &:disabled {
//     background-color: rgba($color: #000000, $alpha: 0.24) !important;
//     color: rgba($color: #000000, $alpha: 0.24) !important;
//     border-color: rgba($color: #8f8b8b, $alpha: 0.24) !important;
//     cursor: not-allowed !important;
//   }
//   &:hover {
//     background-color: var(--secondary) !important;
//     // border: solid 2px var(--secondary) !important;
//     color: white !important;
//   }
// }

.btn--rounder {
  border-radius: 8px !important; 
  padding: 6px 22px !important;
  text-transform: none !important;
  box-shadow: none !important;
  transition: ease-in all 0.3s;
  font-weight: 500 !important; 
  line-height: 21px !important; 

  &:disabled {
    background-color: rgba(0, 0, 0, 0.24) !important;
    color: rgba(0, 0, 0, 0.24) !important;
    border-color: rgba(143, 139, 139, 0.24) !important;
    cursor: not-allowed !important;
  }

  &:hover {
    background-color: var(--secondary) !important; 
    color: white !important; 
  }
}

.btn--rounder--large {
  border-radius: 20px !important; 
  padding: 6px 22px !important;
  text-transform: none !important;
  box-shadow: none !important;
  transition: ease-in all 0.3s;
  font-weight: 500 !important; 
  font-size: 16px !important;
  line-height: 21px !important; 

  &:disabled {
    background-color: rgba(0, 0, 0, 0.24) !important;
    color: rgba(0, 0, 0, 0.24) !important;
    border-color: rgba(143, 139, 139, 0.24) !important;
    cursor: not-allowed !important;
  }

  &:hover {
    background-color: var(--secondary) !important; 
    color: white !important; 
  }
}

.btn--rounder--sm {
  padding: 3px 16px !important;
}
.btn--primary {
  &:hover {
    background-color: var(--primary-color) !important;
    border: solid 2px var(--primary-color) !important;
    color: white !important;
  }
  transition: ease-in all 0.3s;
  background-color: transparent !important;
  border: solid 2px var(--primary-color) !important;
  color: var(--primary-color) !important;
}
.btn--secondary {
  background-color: transparent !important;
  border: solid 2px var(--bs-gray) !important;
  color: var(--bs-gray) !important;
}

.btn--primary--filled {
  background-color: var(--primary-color) !important;
  border: solid 2px var(--primary-color) !important;
  color: white !important;
}

.btn--grayMain {
  background-color: var(--bs-white) !important;
  border: solid 1px var(--bs-gray-main) !important;
  color: var(--bs-gray-dark) !important;
}

.field_identification {
  .MuiButtonBase-root {
    padding: 4px 9px;
  }
}
.input_tag {
  width: 400px;
}

.modal_tag {
  width: 700px;
}

.pointer {
  cursor: pointer;
}

.link {
  &:hover {
    transition: ease-in all 0.3s;
    color: var(--secondary);
  }
}

.btn_icon {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.bg__primary {
  background-color: var(--primary-color) !important;
}

.MuiStepLabel-iconContainer {
  .MuiStepIcon-root.MuiStepIcon-active,
  .MuiStepIcon-completed {
    color: var(--primary-color) !important;
  }
}
.spiner_sm {
  width: 1rem !important;
  height: 1rem !important;
  border-width: 0.2em !important;
}

.ReactModal__Overlay {
  opacity: 0;
  z-index: 999;
  transition: opacity 300ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Overlay--after-open {
  background-color: rgba(0, 0, 0, 0.35) !important;
}
.language_select {
  color: #05ae34 !important;
}

::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
}
::-webkit-scrollbar-thumb {
  background: rgb(219, 216, 216);
}
